
import { createUser, queryAgentInfo, queryUserList, userDelete, userRecharge } from "@/service/apiService";
import { Message } from "element-ui";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {},
})
export default class UserView extends Vue {
  private agentInfo: any = {
    totalUserNum: 0,
    totalDeviceNum:  0,
    memberCount:  0,
    leftCount: 0,
    invitationCode: '',
  }

  private addUserFormVisible: boolean = false
  private modifyFormVisible: boolean = false

  private userPageList: Array<any> = []

  private selectUserList: Array<any> = []

  private userQueryParm = {
    keywords: '',
    page: 1,
    size: 20,
    total: 0
  }

  private addUserParm = {
    account: '',
    password: '',
    loading: false
  }

  private modifyUserParm = {
    userId: 0,
    account: '',
    remark: '',
    count: 0,
    loading: false
  }

  private mounted() {
    this.getAgentInfo()
    this.getUserList()
  }

  private handleView(row: any) {
    this.$router.push({ path: '/app/index?userId=' + row.id })
  }

  private handleUpdate(row: any) {
    this.modifyFormVisible = true
    this.modifyUserParm.userId = row.id
    this.modifyUserParm.account = row.account
    this.modifyUserParm.remark = row.remark
    this.modifyUserParm.count = 0
  }

  private handleModifyUserSubmit() {
    this.modifyUserParm.loading = true
    userRecharge(this.modifyUserParm.userId, this.modifyUserParm.count, this.modifyUserParm.remark)
      .then(rep => {
        Message.success("充值成功");
        this.getUserList()
        this.modifyUserParm.loading = false
        this.modifyFormVisible = false
      }).catch(() => {
        this.modifyUserParm.loading = false
      })
  }

  private handleDelete(row: any) {
    this.$confirm(`此操作将永久删除该用户 { ${row.account} }, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.submitDeleteUser(row.id)
        .then(() => {
          Message.success("删除成功")
          this.getUserList()
        })
    }).catch(() => {});
  }

  private async submitDeleteUser(userId: number) {
    userDelete(userId).then(rep => {
      Promise.resolve(rep)
    }).catch((error) => {
      Promise.reject(error)
    })
  }

  private handlDeleteSelected() {
    this.$confirm(`此操作将永久删除选中用户, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      let pms: Array<Promise<any>> = []
      this.selectUserList.map( item => {
        let p = this.submitDeleteUser(item.id)
        pms.push(p)
      })
      Promise.all(pms)
        .then(() => {
          Message.success("删除成功")
          this.getUserList()
        })
    }).catch(() => {});
  }

  private handleAddUserSubmit() {
    this.addUserParm.loading = true
    createUser(this.addUserParm.account, this.addUserParm.password)
      .then(rep => {
        this.getUserList()
        this.addUserParm.loading = false
        this.addUserFormVisible = false
      }).catch(() => {
        this.addUserParm.loading = false
      })
  }

  private handleAdd() {
    this.addUserFormVisible = true
    this.addUserParm.account = ''
    this.addUserParm.password = ''
  }

  private handleSearch() {
    this.userQueryParm.page = 1
    this.getUserList()
  }

  private handleSelectionChange(val: any) {
    this.selectUserList = val
  }

  private handleSizeChange(val: number) {
    this.userQueryParm.size = val
    this.getUserList()
  }

  private handleCurrentChange(val: number) {
    this.userQueryParm.page = val
    this.getUserList()
  }

  private getAgentInfo() {
    queryAgentInfo().then(rep => {
      this.agentInfo = rep.data
    }).catch(() => {})
  }

  private getUserList() {
    queryUserList(this.userQueryParm.keywords,this.userQueryParm.page, this.userQueryParm.size)
      .then(rep => {
        this.userPageList = rep.data.list
        this.userQueryParm.total = rep.data.total
      })
  }

}
